
@font-face {
  font-family: 'Satoshi';
  src: url('/fonts/Satoshi-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('/fonts/Satoshi-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('/fonts/Satoshi-Italic.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Satoshi';
  src: url('/fonts/Satoshi-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Satoshi';
  src: url('/fonts/Satoshi-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('/fonts/Satoshi-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Satoshi';
  src: url('/fonts/Satoshi-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('/fonts/Satoshi-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Satoshi';
  src: url('/fonts/Satoshi-Black.ttf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('/fonts/Satoshi-BlackItalic.ttf');
  font-weight: 900;
  font-style: italic;
}
